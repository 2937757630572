
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/anquanxunjian.ts";
import { parseTime } from "@/utils";
@Options({
  name: "Anquanxunjian",
})
export default class extends Vue {
  private allQuestions = [
    { text: "全部问题", value: 0 },
    { text: "通知我的", value: 1 },
    { text: "我创建的", value: 2 },
    { text: "我处理的", value: 3 },
  ];
  private allStatus = [
    { text: "全部状态", value: 0 },
    { text: "未处理", value: 1 },
    { text: "已处理", value: 2 },
    { text: "已解决", value: 3 },
    { text: "漏检", value: 4 },
  ];
  private selectType = 0;
  private selectStatus = 0;
  refreshing = false;
  loading = false;
  finished = false;
  calendarShow = false;
  date: Date | string = "";

  safeList: any[] = [];
  page = {
    pageSize: 10,
    pageNum: 1,
  };
  mounted() {
    (this as any).$mitter.on("refresh", () => {
      this.refreshing = true;
      this.onRefresh();
    });
  }
  fGoDetail(ployload: any) {
    ployload.checkType = this.selectType;
    this.$router.push({ name: "Xunjiandetail", params: ployload });
  }
  fGoAdd() {
    this.$router.push("/app/xunjianadd");
  }
  currentDate() {
    return parseTime(this.date, "{m}月{d}日");
  }
  fConfirmDate(d: Date) {
    this.date = d;
    this.fChangeSearch();
    this.calendarShow = false;
  }
  fChangeSearch() {
    this.refreshing = true;
    this.onRefresh();
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.safeList = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      const reqjson = {
        projectId: this.$store.state.project.projectinfo.projectId,
        status: this.selectStatus ? this.selectStatus : null,
        createDate: this.date ? parseTime(this.date, "{y}-{m}-{d}") : null,
        ...this.page,
      };
      let runfun = null;
      if (this.selectStatus !== 4) {
        if (this.selectType === 0) {
          runfun = api.mobilesourceCheckpageAll(reqjson);
        } else if (this.selectType === 1) {
          runfun = api.mobilesourceCheckpageNotice(reqjson);
        } else if (this.selectType === 2) {
          runfun = api.mobilesourceCheckpageCreate(reqjson);
        } else {
          runfun = api.mobilesourceCheckpageDeal(reqjson);
        }
      } else {
        runfun = api.projectDangerCheckLogpage(reqjson);
      }
      runfun.then((res: any) => {
        if (res.code === 0) {
          this.safeList = [...this.safeList, ...res.data.records];
          this.page.pageNum++;
          this.loading = false;
          if (this.safeList.length >= res.data.total) {
            this.finished = true;
          }
        }
      });
    }, 1000);
  }
  fSetTime() {
    this.date = "";
    this.calendarShow = false;
    this.fChangeSearch();
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
}
